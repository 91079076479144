/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
// import { Map } from 'immutable';
import moment from 'moment';
import { isEmpty, isString, isObject, isArray, isNaN } from 'lodash';
import appAction from '@iso/redux/app/actions';
import { fileTypes, sheetFTypes } from './utilityData';
import getApiData from './apiHelper';

const { setVideoUploadSize, setImageUploadSize } = appAction;

export function clearToken() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('user_data');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return idToken;
  } catch (err) {
    clearToken();
    return '';
  }
}

export function checkCsv(file) {
  const fType = file && file.type ? file.type : '';
  const isExcelCsv = sheetFTypes.includes(fType);
  const isLt10M = file.size / 1024 / 1024 < 10;

  let message = '';
  let status = true;

  if (!isExcelCsv) {
    message = 'You can upload only CSV file';
    status = false;
  } else if (!isLt10M) {
    message = 'File must be smaller than 10MB!';
    status = false;
  }

  return { message, status };
}
export function getLanguage() {
  try {
    const language = localStorage.getItem('language');
    return language;
  } catch (err) {
    return '';
  }
}

export function getCountry() {
  try {
    const country = localStorage.getItem('country');
    const parsedCountry = country ? JSON.parse(country) : {};
    return parsedCountry;
  } catch (err) {
    return {};
  }
}
export function getUserData() {
  try {
    const userData = localStorage.getItem('user_data');
    return userData;
  } catch (err) {
    clearToken();
    return {};
  }
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  }
  if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getParsedJson(json) {
  if (isString(json) && !isEmpty(json)) return JSON.parse(json);
  return json;
}
export function getSlug(string, type) {
  if (!isEmpty(type)) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace('&', 'and')
      .replace(/[&_\/\\#,+()$~%.'":*?<>{}]/g, '-')
      .replace(/\s+/g, '_')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace('&', 'and')
    .replace(/[&_\/\\#,+()$~%.'":*?<>{}]/g, '-')
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function disabledDate(current) {
  // if (type === 'today') {
  // Disable previous dates
  return current < moment().startOf('day');
  // }
  // Disable previous dates + today
  // return current && current < moment().endOf('day');
}

export function disableFutureData(current) {
  return current > moment().endOf('day');
}
export function disablePastData(current) {
  return current < moment().startOf('day');
}
export function getFullName(fname, lname) {
  if (fname && lname) return `${fname} ${lname}`;
  if (fname) return fname;
  return '';
}

export function dropdownFilter(input, option) {
  return option.props.children
    ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    : false;
}
export function countryFilter(input, option) {
  return (
    option.props.countryname.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}
export function languageFilter(input, option) {
  return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export function chatFilesVal(type) {
  const fTypes = isObject(fileTypes) ? fileTypes : {};
  if (fTypes[type]) {
    return true;
  }
  return false;
}

export function formatFileSize(bytes, decimalPoint = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 * @function checkImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkImage(file, size = 10) {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg' ||
    file.type === 'image/webp';
  let message = '';
  let status = true;
  const isLt2M = file.size / 1024 / 1024 < Number(size);
  if (!isJpgOrPng) {
    message = 'You can only upload JPG/PNG/JPEG file!';
    status = false;
  } else if (!isLt2M) {
    message = `Image must smaller than ${size}MB!`;
    status = false;
  }

  return { message, status };
}

/**
 * @function checkImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkVideo(file, size) {
  let message = '';
  let status = true;
  const isLt10M = file.size / 1024 / 1024 < size;
  if (!isLt10M) {
    message = `Video must smaller than ${size}MB!`;
    status = false;
  }

  return { message, status };
}

/**
 * @function checkSvgImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkSvgImage(file) {
  const isJpgOrPng = file.type === 'image/svg+xml' || file.type === 'image/svg';
  let message = '';
  let status = true;
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isJpgOrPng) {
    message = 'You can only upload SVG file!';
    status = false;
  } else if (!isLt2M) {
    message = 'Image must smaller than 2MB!';
    status = false;
  }
  return { message, status };
}

export function fixAutocomplete() {
  if (document) {
    document.querySelectorAll('input').forEach((e) => {
      // you can put any value but NOT "off" or "false" because they DO NOT works
      e.setAttribute('autocomplete', 'stopAutocomplete');
    });
  }
}

export function getCordZip(res) {
  if (isObject(res) && !isEmpty(res)) {
    const coordinates =
      res && isObject(res.location) && isArray(res.location.coordinates)
        ? res.location.coordinates
        : [];
    let location = {};
    if (!isEmpty(coordinates)) {
      location = {
        lat: coordinates[0],
        lng: coordinates[1],
      };
    }
    return location;
  }
  return {};
}

/**
 * @function getSortOrder
 * @param {string} order Return type of for sorting for API
 *
 */
export function getSortOrder(order) {
  if (order === 'ascend') {
    return 'ASC';
  }
  if (order === 'descend') {
    return 'DESC';
  }
  return order;
}

/**
 * Return Allowed Permission to User as Array
 * @function getPermissionArray
 * @param {object} data It is UserData which is saved in Redux at the Login time
 *
 */
export function getPermissionArray(data) {
  let permAray = [];
  if (!isEmpty(data) && !isEmpty(data.permissions)) {
    permAray = data.permissions.split('|');
  }
  return permAray;
}

export function getPermArr() {
  return ['Dashboard', 'Email', 'CMS', 'FAQ'];
}

export function colorArray() {
  return [
    { name: 'A', Color: '#1abc9c' },
    { name: 'B', Color: '#f56a00' },
    { name: 'C', Color: '#c0392b' },
    { name: 'D', Color: '#3498db' },
    { name: 'E', Color: '#9b59b6' },
    { name: 'F', Color: '#34495e' },
    { name: 'G', Color: '#16a085' },
    { name: 'H', Color: 'rgba(63, 81, 181,1.0)' },
    { name: 'I', Color: '#2980b9' },
    { name: 'J', Color: '#8e44ad' },
    { name: 'K', Color: '#2c3e50' },
    { name: 'L', Color: '#f1c40f' },
    { name: 'F', Color: '#e74c3c' },
    { name: 'M', Color: '#f39c12' },
    { name: 'N', Color: '#273c75' },
    { name: 'O', Color: '#c0392b' },
    { name: 'P', Color: '#f39c12' },
    { name: 'Q', Color: '#7265e6' },
    { name: 'R', Color: '#00a8ff' },
    { name: 'S', Color: '#e1b12c' },
    { name: 'T', Color: '#689F38' },
    { name: 'U', Color: '#353b48' },
    { name: 'V', Color: '#dcdde1' },
    { name: 'W', Color: '#c23616' },
    { name: 'X', Color: '#00a8ff' },
    { name: 'Y', Color: '#4cd137' },
    { name: 'Z', Color: '#9c88ff' },
  ];
}

export async function fetchVideoUploadSize(dispatch) {
  try {
    const resMaxVideoUploadSize = await getApiData(
      'getSetting',
      { key: 'video_upload_in_size' },
      'GET',
    );
    const resMaxImageUploadSize = await getApiData(
      'getSetting',
      { key: 'image_upload_size' },
      'GET',
    );
    if (resMaxVideoUploadSize.success && resMaxVideoUploadSize.data) {
      dispatch(setVideoUploadSize(Number(resMaxVideoUploadSize.data.value)));
    }
    if (resMaxImageUploadSize.success && resMaxImageUploadSize.data) {
      dispatch(setImageUploadSize(Number(resMaxImageUploadSize.data.value)));
    }
  } catch (err) {
    console.log('🚀 ~ file: app.js:27 ~ fetchData ~ err:', err);
    // notification({ type: 'error', message: 'Something went wrong.' });
  }
}
